<template>
  <b-form-group
    label="Caption"
    label-for="caption"
  >
    <div
      class="collapse-textarea"
      :class="{expanded: !isExpanded}"
    >
      <textarea
        class="expandable-textarea"
        :class="[isExpanded ? 'not-expanded' : 'expanded']"
        @input="handleInput"
        contentEditable
      />
      <div @click="isExpanded = !isExpanded">
        <span
          class="expand-arrow mt-n1"
          :class="[isExpanded ? 'not-expanded' : 'expanded']"
        >&#187;</span>
      </div>
    </div>
  </b-form-group>
</template>

<script>
import {BFormGroup} from "bootstrap-vue";

export default {
  components: {
    BFormGroup,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      isExpanded: false,
    }
  },
  methods: {
    handleInput (e) {
      this.$emit('changeDescription', e.target.value, this.index)
    }
  }
}
</script>

<style scoped>

</style>