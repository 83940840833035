<template>
  <div>
    <b-row>
      <b-col>
        <h1>Upload Activity Thumbnail</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h2>Case {{ actionObj.CaseFileNumber }}</h2>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col>
        <router-link
          :to="'/cases/edit-action/' + actionObj.ActionID"
        >
          <h2>Action {{ actionObj.ActionFileNumber }}</h2>
        </router-link>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <router-link
          :to="'/cases/' + actionObj.CaseID + '/detail'"
        >
          <h3>Case {{ actionObj.CaseFileNumber }}</h3>
        </router-link>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="2">
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="button"
            :variant="'outline-primary'"
            class="mr-1"
            @click.prevent="chooseFile()"
          >
            Choose File
          </b-button>
        </div>
      </b-col>
      <b-col>
        <b-row
          v-for="(item, index) in Files"
          :key="item.lastModified"
        >
          <b-col md="4">
            <span class="file-name-wrapper">{{ item.FileName }}</span>
          </b-col>
          <b-col md="6">
            <CaptionThumbnail
              v-model="item.Description"
              @changeDescription="changeDescription"
              :index="index"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="pt-2">
      <b-col md="2">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          :variant="'outline-danger'"
          class="mr-1"
          @click.prevent="cancelFile()"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col md="2" v-if="Files && Files.length">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          :variant="'outline-primary'"
          class="mr-1"
          @click.prevent="uploadThumbnail()"
        >
          Upload Thumbnail
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {BButton, BCol, BFormGroup, BRow} from "bootstrap-vue";
import APIService from "@core/utils/APIService";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import CaptionThumbnail from "@/views/cases/components/CaptionThumbnail";

const apiService = new APIService();

export default {
  components: {
    CaptionThumbnail,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      ActionID: "",
      ActivityID: "",
      actionObj: {},

      Files: [],
    }
  },
  created() {
    this.loadAction();
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },
    loadAction() {
      this.ActionID = this.$route.params.actionID;
      this.ActivityID = this.$route.params.activityID;
      apiService
          .get("action/" + this.ActionID + "/detail")
          .then(res => {
            this.actionObj = res.data;
          })
    },
    chooseFile() {
      const input = document.createElement('input');
      input.type = 'file';
      input.onchange = e => {
        const file = e.target.files[0];
        const FileData = {
          FileName: file.name,
          FileSize: file.size,
          CreatedAt: file.lastModified,
          file: file,
        }
        this.Files.push(FileData)
      }
      input.click();
    },

    cancelFile() {
      this.$router.push('/cases/view-activity/' + this.ActivityID + "/MEDIA");
    },

    changeDescription(description, index) {
      this.Files[index].Description = description
    },

    uploadThumbnail() {
      let index = 0;
      const _this = this;
      if (this.ActivityID) {
        this.Files.map(item => {
          let formData = new FormData();
          const data = {
            ParentObjectKey: this.ActivityID,
            ParentObjectType: "Activity",
            UserTypePermissions: "User;Investigator",
            description: item.Description
          }
          const documentJson = JSON.stringify(data)
          formData.append("FormFile", item.file);
          formData.append("Document", documentJson);
          apiService
              .post("file/create", formData, {'Content-Type': 'multipart/form-data'})
              .then(function () {
                index++
                if (index === _this.Files.length) {
                  _this.Files = [];
                }
                this.cancelFile();
              })
              .catch(function (res) {
                console.log(res);
              });
        })
      } else {
        this.showToast('danger', 'top-center', 4000, JSON.stringify("You don't have Activity id"));
      }
    },
  }
}
</script>

<style scoped>
.file-name-wrapper {
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>